import React from "react"
import { useRouter } from "next/router"
import {
  TopNavigationClick,
  InternalPromotionClick,
  SelectPromotion,
} from "../../../utils/gtm"

function ButtonTriangleTracking({
  children,
  href,
  classList,
  triangle,
  openInNewTab,
  notHaveWrapper,
  trackingEvent,
  trackingName,
  trackingPromoId,
  color,
  dataLayerIndex,
  id,
  WaId
}) {
  const router = useRouter()
  const handleClick = async e => {
    e.preventDefault()
    if (trackingEvent === "TopNavigationClick") {
      TopNavigationClick(href)
    }
    if (trackingEvent === "PromotionClick" && trackingPromoId) {
      const title = document ? document.title : ""
      InternalPromotionClick(trackingPromoId, trackingName, title)
    }

    if (trackingPromoId) {
      const items = [{ item_id: trackingPromoId, trackingName }];
      const arrParrent = [];
      const promotions = document.querySelectorAll("[data-layer-event=view_promotion]");
      if(promotions && Array.from(promotions).length > 0){
        Array.from(promotions).forEach((promotion) => {
          if(promotion.getAttribute('data-layer-id') && !arrParrent.includes(promotion.getAttribute('data-layer-id'))){
            arrParrent.push(promotion.getAttribute('data-layer-id'));
          }
        });
      }
      Array.from(arrParrent).forEach((promotion, index) => {
        if(promotion === trackingPromoId){
           SelectPromotion(items,index + 1);
        }
      });
    }

    if (href.charAt(0) === "/") await router.push(href)
    else window.open(href, "_blank")
  }

  let buttonColor

  switch (color) {
    case "Button Red":
      buttonColor = "btn-red"
      break
    case "Button Yellow":
      buttonColor = "btn-yellow"
      break
    case "Button Yellow Border":
      buttonColor = "btn-yellow-border"
      break
    case "Button Black Border":
      buttonColor = "btn-black-border"
      break
    default:
      buttonColor = "btn-yellow"
  }

  return (
    <div
      className={`${notHaveWrapper ? "pl-9" : "btn-wrapper"}`}
      data-layer-component="button-triangle-tracking"
    >
      <a
        className={`${triangle ? "btn-triangled" : "btn"} ${
          classList || ""
        } ${buttonColor}`}
        target={openInNewTab ? "_blank" : "_self"}
        rel="noreferrer"
        id={id || null}
        onClick={handleClick}
        href={href}
        data-layer-event="view_promotion"
        data-layer-name={trackingName}
        data-layer-id={trackingPromoId}
        data-layer-index={dataLayerIndex}
        data-wa-id={WaId}
      >
        {children}
        {triangle && (
          <svg
            className="triangle fill-current w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 19 16"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.863 15.585L18.773.152H.953l8.91 15.433z"
            />
          </svg>
        )}
      </a>
    </div>
  )
}

export default ButtonTriangleTracking
